<template>
  <div id="query_use">
    <div class="users-management">
    <strong style="font-size:13px">授权申请</strong>
    <div class="buttons">
    <el-select v-model="active"
         placeholder="请选择申请状态" style="width:16vw;" clearable>
          <el-option
            v-for="state in options"
            :key="state.key"
            :value="state.value"
            :label="state.label"
          ></el-option><i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-select>
        <el-date-picker
                  v-model="searchdate"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  style="width: 16vw;">
                </el-date-picker>
    <el-button @click="querySearchAsync(number,active,searchdate)" type="primary" size="mini"  style="line-height: 20px;"  icon="el-icon-search">搜索
    </el-button>
    <el-button @click="refresh()" type="primary" size="mini"  style="line-height: 20px;" >重置
    </el-button>
    <el-button @click="editAdd()" type="primary" size="mini"  style="line-height: 20px;">授权申请
     </el-button>
    </div>
  </div>
     <Adddesignerapply
        :title="adddesignerApplyTitle"
        :addUserVisible="addUserVisible"
        @cancel="addUserget"
        :close-on-click-modal = "false"
    ></Adddesignerapply>
    <div class="table-container">
    <el-table :data="tableData" :row-class-name="tableRowClassName"  :row-style="{height: '0.1'}" :cell-style="{padding: '4px -15px'}">
      <el-table-column prop="id" label="申请编号" align="center"></el-table-column>
      <el-table-column prop="applytime" label="申请时间" align="center"></el-table-column>
      <el-table-column prop="applyby" label="申请人" align="center"></el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
              <el-tag type="text" effect="dark" v-if="scope.row.applystatus=='待审核'">待审核</el-tag>
              <el-tag type="success" effect="dark" v-if="scope.row.applystatus=='审核通过'">审核通过</el-tag>
              <el-tag type="danger" effect="dark" v-if="scope.row.applystatus=='审核拒绝'">审核拒绝</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="180px"
        align="center">
        <template slot-scope="scope">
          <el-button-group>
            <el-button @click="downloadlicense(scope.row)" type="text" style="margin-right:10px" v-if="scope.row.applystatus=='审核通过'">下载</el-button>
          <el-button @click="showEditDialog(scope.row)" type="text"  >查看详情</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    </div>
  <div style="text-align: right; padding: 30px">
      <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page.sync="currentpage"
      :page-size="pagesize"
      layout="prev, pager, next, jumper"
      :total="totalcount">
    </el-pagination>
  </div>
  <div>
  <el-dialog
      title="授权申请详情页"
      :visible.sync="editDialogVisible"
      width="800px"
      :before-close = "editDialogClosed"
      :close-on-click-modal = "false"
      >
       <el-form label-position="right" label-width="100px" :inline="true" :model="editForm"  ref="editFormRef"
                     style="padding: 0px 70px 0px 10px;" class="demo-ruleForm">
          <el-form-item label="申请用户：">
            <el-input v-model="editForm.applyby" placeholder="申请用户" disabled></el-input>
          </el-form-item>
          <el-form-item label="申请时间：">
              <el-input v-model="editForm.applytime"  placeholder="申请时间" disabled ></el-input>
          </el-form-item>
          <el-form-item label="有效时间：">
              <el-input v-model="editForm.licensetime"  placeholder="有效时间" disabled ></el-input>
          </el-form-item>
          <el-form-item label="申请原因：">
              <el-input type='textarea' v-model="editForm.applycause"  placeholder="申请原因" disabled></el-input>
          </el-form-item>
        </el-form>
              <div style="margin-top: 30px;border-style:double none none none ;">
                <div style="margin-top: 30px;margin-left: 20px;">
                <el-form label-position="right" label-width="100px" :inline="true" :model="editForm"  ref="editFormRef"
                     style="padding: 0px 70px 0px 10px;" class="demo-ruleForm">
                  <el-form-item label="审批用户：">
                    <el-input v-model="editForm.approvalby" placeholder="请耐心等待" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="审批状态：">
                      <el-input v-model="editForm.applystatus"  placeholder="请耐心等待" disabled ></el-input>
                  </el-form-item>
                  <el-form-item label="审批结果：">
                    <el-input v-model="editForm.result" placeholder="请耐心等待" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="审批时间：">
                      <el-input v-model="editForm.approvaltime"  placeholder="请耐心等待" disabled ></el-input>
                  </el-form-item>
                  <el-form-item label="拒绝原因：">
                      <el-input type='textarea' v-model="editForm.refusecause"   disabled></el-input>
                  </el-form-item>
                </el-form>
                </div>
              </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogClosed">返回</el-button>
      </span>
    </el-dialog>
  </div>
</div>
</template>

<script>
import Adddesignerapply from './adddesignerapply.vue'
// import { Message } from 'element-ui'

export default {
  name: 'designerapply',
  components: {
    Adddesignerapply
  },
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      searchdate: '',
      // 默认显示第几页
      currentpage: 1,
      // 总条数
      totalcount: 20,
      // 默认每页显示的条数
      pagesize: 10,
      dataList: [],
      tableData: [],
      active: '',
      options: [{
        key: 100,
        value: '100',
        label: '待审核'
      }, {
        key: 200,
        value: '200',
        label: '审核通过'
      }, {
        key: 300,
        value: '300',
        label: '审核拒绝'
      }],
      number: '',
      currentPage3: 1,
      dialogFormVisible: false,
      addUserVisible: false,
      adddesignerApplyTitle: '授权申请',
      editDialogVisible: false,
      editForm: {
        applyby: '',
        applytime: '',
        applycause: '',
        approvalby: '',
        applystatus: '',
        result: '',
        approvaltime: '',
        refusecause: ''
      }
    }
  },
  created () {
    this.getdatas(this.pagesize, this.currentpage - 1)
  },
  methods: {
    querySearchAsync (number, active, searchdate) {
      console.log(number, active, searchdate)
      this.axios.post('/users/searchlicenseapply/', {
        employeeid: localStorage.getItem('user_id'),
        searchnumber: number,
        searchactive: active,
        searchtime: searchdate
      }).then((res) => {
        this.tableData = res.data.data
        // this.totalcount = ''
      }).catch((error) => {
        console.log(error)
      })
    },
    refresh () {
      console.log('刷新')
      this.active = ''
      this.number = ''
      this.searchdate = ''
      this.getdatas(this.pagesize, this.currentpage - 1)
    },
    downloadlicense (licensedata) {
      console.log('licensedata', licensedata)
      this.axios.post('/users/downloadlicense/', {
        licenseid: licensedata.licenseid,
        licensetime: licensedata.licensetime,
        approvaltime: licensedata.approvaltime
      }, {
        responseType: 'blob' // 确保响应类型为blob
      }).then((res) => {
        console.log(res)
        const filename = `${licensedata.licenseid}_${licensedata.licensetime}.license`;
        // 创建一个下载链接并模拟点击进行下载
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        // 设置下载文件名，这里可以根据实际情况调整
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch((error) => {
        console.log(error)
      })
    },
    getdatas (n1, n2) {
      this.axios.post('/users/licenseapply/', {
        employeeid: localStorage.getItem('user_id'),
        limit: n1,
        offset: n2
      }).then((res) => {
        this.tableData = res.data.data
        this.totalcount = res.data.numbers[0].numbers
      }).catch((error) => {
        console.log(error)
      })
    },
    showEditDialog (row) {
      this.editForm = row
      this.axios.post('/users/selectusersapply/', {
        applyid: row.id
      }).then((res) => {
        this.dataList = res.data.data
        this.editDialogVisible = true
      }).catch((error) => {
        console.log(error)
      })
    },
    addUserget () {
      setTimeout(() => {
        this.getdatas(this.pagesize, this.currentpage - 1)
        this.addUserVisible = false
      }, 200)
    },
    editDialogClosed () {
      this.editDialogVisible = false
    },
    editAdd () {
      this.addUserVisible = true
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'success-row'
      }
    },
    // 显示第几页
    handleCurrentChange (val) {
      console.log('显示第几页', val, val * 10)
      // 改变第几页
      this.currentpage = val
      this.getdatas(this.pagesize, (val - 1) * 10)
    }
  }
}
</script>

<style scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table >>> .success-row {
  background: #f0f9eb;
}
.el-divider{
   margin: 1px;
}

.table-container {
  display: flex;
  flex-direction: column;
  height: 100;
  position: relative;
}
.users-management {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}
.users-management strong {
  font-size: 2vw;
}
.buttons {
  display: flex;
  gap: 10px;
}
</style>
